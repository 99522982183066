import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Shop Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/shop/"
    metaDescription="Uizard e-commerce shop UI component templates include a booking card, a cart overview card, and product listing details, among others. Explore now."
    description="
    h2:Everything you need to design a compelling digital shopping experience
    <br/>
    Drag-and-drop pre-made component template for any shopping or e-commerce mobile app or website.
    <br/>
    Uizard has many component template that you can simply drag-and-drop when building a shopping or e-commerce mobile app or website. These Shop component template include a booking card, a cart overview card, and product listing details, among others.
    <br/>
    h3:Shop component template that do more than increasing your conversion rate
    <br/>
    Shop component template in Uizard also help you create a UI that will enable your mobile users to conveniently find and purchase products they are looking for.
    <br/>
    h3:Uizard creates unique Shop component template for rapid development
    <br/>
    You can drag-and-drop the Uizard Shop component template when designing your own online store with no prior design experience required. The full customization capability of Uizard allows you to change the design to be completely in line with your brand style and colors. You can do this in one click when you use Uizard's AI to generate a theme from any image, URL, or Sketch file!
    <br/>
    Leverage Uizard's Shop component template to make designs that make your users feel better and reduce the friction in the experience when using your application.
    "
    pages={[
      "Booking card",
      "Landing page content",
      "Cart overview card",
      "Item list with visuals",
      "Feature cards round image",
      "Product photo gallery card",
      "Comments or reviews",
      "Product listing details",
      "Large landing page section",
      "Product categories",
      "Order confirmation",
      "Shop product overview",
      "Cart overview",
      "Web shop footer",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing online shops"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing online shops"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing online shops"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing online shops"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing online shops"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/component-templates/shop/shop_1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/component-templates/shop/shop_2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/component-templates/shop/shop_3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/component-templates/shop/shop_4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/component-templates/shop/shop_5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
